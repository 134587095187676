import {
  Box,
  Flex,
  Image,
  Skeleton, Text, useColorModeValue,
} from '@chakra-ui/react';
import {motion} from 'framer-motion';
import React from 'react';

import type {Block} from 'types/api/block';

import config from 'configs/app';
import getBlockTotalReward from 'lib/block/getBlockTotalReward';
import BlockTimestamp from 'ui/blocks/BlockTimestamp';
import AddressEntity from 'ui/shared/entities/address/AddressEntity';
import BlockEntity from 'ui/shared/entities/block/BlockEntity';

import IconSvg from '../shared/IconSvg';
import {isAddressEqual} from "viem";
import useIsMobile from "../../lib/hooks/useIsMobile";

type Props = {
  block: Block;
  isLoading?: boolean;
  index: number;
}

const LatestBlocksItem = ({block, isLoading, index}: Props) => {
  const totalReward = getBlockTotalReward(block);
  const bgColor = useColorModeValue('blue.50', 'whiteAlpha.100');
  const minerColor = useColorModeValue('blue.100', 'whiteAlpha.200');
  const KingPng = '/static/king.png';
  const isMobile = useIsMobile();
  return (
    <Box
      position="relative"
      zIndex={33}
      as={motion.div}
      backgroundColor={bgColor}
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{display: 'none'}}
      transitionDuration="normal"
      transitionTimingFunction="linear"
      borderRadius="md"
      border="0px solid"
      borderColor="divider"
      p={3}
      w="100%"
      pos={'relative'}
    >
      <Flex alignItems="center" overflow="hidden" w="100%">
        <BlockEntity
          isLoading={isLoading}
          number={block.height}
          tailLength={2}
          lineHeight={7}
          fontWeight={500}
          mr="auto"
        />
        {!config.features.rollup.isEnabled && !config.UI.views.block.hiddenFields?.miner && (
          <Box fontSize="xs" backgroundColor={minerColor} borderRadius="4px" width="fit-content" px={2} py={1}
               ml="10" display="inline-flex" cursor="pointer" alignItems="center">
            <Text textTransform="capitalize" color="text_secondary">Miner:</Text>
            <AddressEntity
              address={block.miner}
              isLoading={isLoading}
              noIcon
              noCopy
              truncation="constant"
              ml="2"
            />
            <IconSvg ml="1" name="east-mini" color="text_secondary" transform="rotate(180deg)" boxSize={3}/>
          </Box>
        )}
      </Flex>
      <Box fontSize="sm" w="100%">
        <Flex w="100%" justifyContent="space-between" mt="2" mb="2">
          <BlockTimestamp
            ts={block.timestamp}
            isEnabled={!isLoading}
            isLoading={isLoading}
            fontSize="sm"
            flexShrink={0}
            ml={0}
          />
          <Box width="fit-content" display="inline-flex">
            <Text w="fit-content" color="text_secondary">Txns:</Text>
            <Skeleton w="fit-content" isLoaded={!isLoading} ml={2}><span>{block.tx_count}</span></Skeleton>
          </Box>
        </Flex>
        <Flex w="100%" justifyContent="space-between">
          {!config.features.rollup.isEnabled && !config.UI.views.block.hiddenFields?.total_reward && (
            <Box width="fit-content" display="inline-flex">
              <Text color="text_secondary">Reward</Text>
              <Skeleton isLoaded={!isLoading} ml={2}><span>{totalReward.dp(10).toFixed()}</span></Skeleton>
            </Box>
          )}
          {!config.features.rollup.isEnabled && !config.UI.views.block.hiddenFields?.miner && (
            <Box width="fit-content" display="flex" alignItems="center">
              <Image
                fontSize="sm"
                w="1rem"
                h="1rem"
                src={KingPng}
                alt="credits icon"
              />
              <Text ml="2" textTransform="capitalize"
                    color="text_secondary">Credits:</Text>
              {
                block?.credits?.map((obj: any, index: number) => {
                  const key = Object.keys(obj)[0];
                  const value = obj[key];
                  const keyStr: any = '0x' + key;
                  let params: any = block?.miner.hash
                  if (isAddressEqual(keyStr, params)) {
                    return <Skeleton key={index} isLoaded={!isLoading} ml="2"><span>{value}</span></Skeleton>
                  }
                })
              }

            </Box>
          )}
        </Flex>
      </Box>
      <Box w={isMobile ? 3 : 4}
           left={isMobile ? -3 : -4}
           pos={'absolute'}
           top={'50%'}
           h={1}
           backgroundImage={'linear-gradient(130deg, rgba(158, 58, 254, 1), rgba(158, 58, 254, 0.8)) 4 4;'}></Box>
    </Box>
  );
};

export default LatestBlocksItem;
