import {Box, Flex, Skeleton, Text, useColorModeValue, VStack} from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';
import useApiQuery from 'lib/api/useApiQuery';
import {HOMEPAGE_STATS} from 'stubs/stats';
import IconSvg from 'ui/shared/IconSvg';

import ChainIndicatorChartContainer from './ChainIndicatorChartContainer';
import useFetchChartData from './useFetchChartData';
import INDICATORS from './utils/indicators';
import {transparentize} from "@chakra-ui/theme-tools";
import useIsMobile from "../../../lib/hooks/useIsMobile";

const indicators = INDICATORS
  .filter(({id}) => config.UI.homepage.charts.includes(id))
  .sort((a, b) => {
    if (config.UI.homepage.charts.indexOf(a.id) > config.UI.homepage.charts.indexOf(b.id)) {
      return 1;
    }

    if (config.UI.homepage.charts.indexOf(a.id) < config.UI.homepage.charts.indexOf(b.id)) {
      return -1;
    }

    return 0;
  });

const ChainIndicators = () => {
  const [selectedIndicator, selectIndicator] = React.useState(indicators[0]?.id);
  const indicator = indicators.find(({id}) => id === selectedIndicator);
  const queryResult = useFetchChartData(indicator);
  const statsQueryResult = useApiQuery('stats', {
    queryOptions: {
      refetchOnMount: false,
      placeholderData: HOMEPAGE_STATS,
    },
  });
  const bgColor:any = useColorModeValue(transparentize('orange.200', 0.14), transparentize('orange.200', 0.14));
  if (indicators.length === 0) {
    return null;
  }

  const valueTitle = (() => {
    if (statsQueryResult.isPlaceholderData) {
      return <Skeleton h="48px" w="215px" mt={3} mb={4}/>;
    }

    if (!statsQueryResult.data) {
      return <Text mt={3} mb={4}>There is no data</Text>;
    }

    return (
      <Text fontWeight={600} fontFamily="heading" fontSize="48px" lineHeight="48px" mt={3}>
        {indicator?.value(statsQueryResult.data)}
      </Text>
    );
  })();

  const valueDiff = (() => {
    if (!statsQueryResult.data || !indicator?.valueDiff) {
      return null;
    }

    const diff = indicator.valueDiff(statsQueryResult.data);
    if (diff === undefined || diff === null) {
      return null;
    }

    const diffColor = diff >= 0 ? 'green.500' : 'red.500';

    return (
      <Skeleton isLoaded={!statsQueryResult.isPlaceholderData} display="flex" alignItems="center" color={diffColor}
                mt={2}>
        <IconSvg name="up" boxSize={5} mr={1} transform={diff < 0 ? 'rotate(180deg)' : 'rotate(0)'}/>
        <Text color={diffColor} fontWeight={600}>{diff}%</Text>
      </Skeleton>
    );
  })();
  const isMobile = useIsMobile();
  return (
    <VStack
      w="100%"
      display="flex"
      alignItems="flex-start"
    >
      <Skeleton w="100%" h="32px'" isLoaded={!statsQueryResult.isPlaceholderData}>
        <Box display="inline-flex" alignItems="center"
             justifyContent={isMobile?"flex-start":"flex-end"} px="4" w="100%" h="32px" backgroundColor={!statsQueryResult.isPlaceholderData?bgColor:''}
             borderRadius={isMobile ? "0px 0px 0px 0px" : "0px 12px 0px 0px" } fontSize="sm">
              Daily Txns </Box>
      </Skeleton>
      <Flex w={'100%'} h={'auto'} flexGrow={1} flexDir="column" order={{base: 2, lg: 1}} p={4}>
        <Box mb={4} overflow={'hidden'} h={'52px'}>
          {valueTitle}
          {valueDiff}
        </Box>
        <ChainIndicatorChartContainer {...queryResult}/>
      </Flex>
    </VStack>
  );
};

export default ChainIndicators;
