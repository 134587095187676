import {Box, Flex, Grid, GridItem, Heading, HStack, VStack} from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';
import useHasAccount from 'lib/hooks/useHasAccount';
import LatestDeposits from 'ui/home/LatestDeposits';
import LatestTxs from 'ui/home/LatestTxs';
import LatestWatchlistTxs from 'ui/home/LatestWatchlistTxs';
import TabsWithScroll from 'ui/shared/Tabs/TabsWithScroll';
import ChainIndicators from "./indicators/ChainIndicators";
import useIsMobile from "../../lib/hooks/useIsMobile";

const rollupFeature = config.features.rollup;

const TAB_LIST_PROPS = {
  mb: {base: 3, lg: 3},
};

const TransactionsHome = () => {
  const hasAccount = useHasAccount();
  if ((rollupFeature.isEnabled && rollupFeature.type === 'optimistic') || hasAccount) {
    const tabs = [
      {id: 'txn', title: 'Latest txn', component: <LatestTxs/>},
      rollupFeature.isEnabled && rollupFeature.type === 'optimistic' && {
        id: 'deposits',
        title: 'Deposits (L1→L2 txn)',
        component: <LatestDeposits/>
      },
      hasAccount && {id: 'watchlist', title: 'Watch list', component: <LatestWatchlistTxs/>},
    ].filter(Boolean);
    return (
      <>
        <Heading as="h4" size="sm" mb={3}>Transactions</Heading>
        <TabsWithScroll tabs={tabs} lazyBehavior="keepMounted" tabListProps={TAB_LIST_PROPS}/>
      </>
    );
  }
  const isMobile = useIsMobile();
  return (
    <>
      <Heading as="h4" size="sm" mb="3">Latest transactions</Heading>
      {
        isMobile ? (
        <VStack alignItems="flex-start" border="1px solid" borderColor="divider" borderRadius="12px">
          <Box w="100%">
            <LatestTxs/>
          </Box>
          <Box w="100%" py={0}>
            <ChainIndicators/>
          </Box>
        </VStack>
        ) : (
          <Flex alignItems="flex-start" border="1px solid" borderColor="divider" borderRadius="12px">
            <Box w="65%">
              <LatestTxs/>
            </Box>
            <Box w="35%">
              <ChainIndicators/>
            </Box>
          </Flex>
        )}

    </>
  );
};

export default TransactionsHome;
