import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import React, { useState } from 'react';

import config from 'configs/app';
import ChainIndicators from 'ui/home/indicators/ChainIndicators';
import LatestBlocks from 'ui/home/LatestBlocks';
import LatestZkEvmL2Batches from 'ui/home/LatestZkEvmL2Batches';
import Stats from 'ui/home/Stats';
import Transactions from 'ui/home/Transactions';
import AdBanner from 'ui/shared/ad/AdBanner';
import ProfileMenuDesktop from 'ui/snippets/profileMenu/ProfileMenuDesktop';
import SearchBar from 'ui/snippets/searchBar/SearchBar';
import WalletMenuDesktop from 'ui/snippets/walletMenu/WalletMenuDesktop';
import BgImg from '/static/home/logoX.png';
import BgMImg from '/static/home/logoX_m.png';
import { StyleFunctionProps, mode } from '@chakra-ui/theme-tools';
import useIsMobile from '../../lib/hooks/useIsMobile';

const rollupFeature = config.features.rollup;
// const result = useColorModeValue('url(/static/home/logoX.png)', 'url(/static/home/logoX.png)');
const result = mode('url(/static/home/logoX_light.png)', 'url(/static/home/logoX_dark.png)')({} as StyleFunctionProps);
// const resizerUrl = mode('url(/static/resizer_light.png)', 'url(/static/resizer_dark.png)')(props);
const BGMIMG = mode('url(/static/home/logoX_m.png)', 'url(/static/home/logoX_m.png)')({} as StyleFunctionProps);

const Home = () => {
  const isMobile = useIsMobile();
  return (
    <Box as="main">
      <Box
        w="100%"
        style={ { backgroundImage: isMobile? BGMIMG : result, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat' } }
        borderRadius={ { base: 'md', lg: 'xl' } }
        px={ { base: 4, lg: 10 } }
        py={ { base: 3, lg: 8 } }
        minW={ { base: 'unset', lg: '900px' } }
        data-label="hero plate"
      >
        <Flex mb={ { base: 2, lg: 6 } } justifyContent="space-between" alignItems="center">
          <Box
            as="h1"
            fontSize={ { base: '18px', lg: '40px' } }
            lineHeight={ { base: '24px', lg: '48px' } }
            fontWeight={ 600 }
            color={ config.UI.homepage.plate.textColor }
          >
            Welcome to { config.chain.name } Explorer
          </Box>
          <Box display={ { base: 'none', lg: 'flex' } }>
            { config.features.account.isEnabled && <ProfileMenuDesktop isHomePage/> }
            { config.features.blockchainInteraction.isEnabled && <WalletMenuDesktop isHomePage/> }
          </Box>
        </Flex>
        <SearchBar isHomepage/>

      </Box>
      <Stats/>
      <AdBanner mt={ 6 } mx="auto" display="flex" justifyContent="center"/>
      <Flex mt={ 6 } direction={ { base: 'column', lg: 'column' } } columnGap={ 12 } rowGap={ 6 }>
        { rollupFeature.isEnabled && rollupFeature.type === 'zkEvm' ? <LatestZkEvmL2Batches/> : <LatestBlocks/> }
        <Box flexGrow={ 1 }>
          <Transactions/>
        </Box>
      </Flex>
    </Box>
  );
};

export default Home;
