import { Box, Heading, Flex, Text, HStack, Skeleton, useColorModeValue } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { AnimatePresence } from 'framer-motion';
import React from 'react';

import type { SocketMessage } from 'lib/socket/types';
import type { Block } from 'types/api/block';

import { route } from 'nextjs-routes';

import config from 'configs/app';
import useApiQuery, { getResourceKey } from 'lib/api/useApiQuery';
import useIsMobile from 'lib/hooks/useIsMobile';
import { nbsp } from 'lib/html-entities';
import useSocketChannel from 'lib/socket/useSocketChannel';
import useSocketMessage from 'lib/socket/useSocketMessage';
import { BLOCK } from 'stubs/block';
import { HOMEPAGE_STATS } from 'stubs/stats';
import LinkInternal from 'ui/shared/LinkInternal';

import IconSvg from '../shared/IconSvg';
import LatestBlocksItem from './LatestBlocksItem';

const LatestBlocks = () => {
  const isMobile = useIsMobile();
  // const blocksMaxCount = isMobile ? 2 : 3;
  let blocksMaxCount: number;
  if (config.features.rollup.isEnabled || config.UI.views.block.hiddenFields?.total_reward) {
    blocksMaxCount = isMobile ? 4 : 5;
  } else {
    blocksMaxCount = isMobile ? 4 : 4;
  }
  const { data, isPlaceholderData, isError } = useApiQuery('homepage_blocks', {
    queryOptions: {
      placeholderData: Array(blocksMaxCount).fill(BLOCK),
    },
  });

  const queryClient = useQueryClient();
  const statsQueryResult = useApiQuery('stats', {
    queryOptions: {
      refetchOnMount: false,
      placeholderData: HOMEPAGE_STATS,
    },
  });

  const handleNewBlockMessage: SocketMessage.NewBlock['handler'] = React.useCallback((payload) => {
    queryClient.setQueryData(getResourceKey('homepage_blocks'), (prevData: Array<Block> | undefined) => {

      const newData = prevData ? [ ...prevData ] : [];

      if (newData.some((block => block.height === payload.block.height))) {
        return newData;
      }

      return [ payload.block, ...newData ].sort((b1, b2) => b2.height - b1.height).slice(0, blocksMaxCount);
    });
  }, [ queryClient, blocksMaxCount ]);

  const channel = useSocketChannel({
    topic: 'blocks:new_block',
    isDisabled: isPlaceholderData || isError,
  });
  useSocketMessage({
    channel,
    event: 'new_block',
    handler: handleNewBlockMessage,
  });

  let content;

  if (isError) {
    content = <Text>No data. Please reload page.</Text>;
  }
  const bgColor = useColorModeValue('white', 'black');
  if (data) {
    const dataToShow = data.slice(0, blocksMaxCount);
    const bgToShow = data.slice(0, blocksMaxCount);
    content = (
      <HStack spacing={isMobile?'3':'4'} mb="3" overflow="scroll" pos="relative">
        <AnimatePresence initial={ false }>
          { dataToShow.map((block, index) => (
            <LatestBlocksItem
              index={index}
              key={ block.height + (isPlaceholderData ? String(index) : '') }
              block={ block }
              isLoading={ isPlaceholderData }
            ></LatestBlocksItem>
          )) }
        </AnimatePresence>
      </HStack>
    );
  }

  return (
    <Box width={{ base: '100%', lg: '100%' }}>
      <Flex alignItems="flex-start" justifyContent="space-between">
        <Heading as="h4" size="sm">Latest blocks</Heading>
        <LinkInternal
          display="inline-flex"
          alignItems="center"
          justifyContent="flex-end"
          fontSize="sm" href={ route({ pathname: '/blocks' }) }>
          View all blocks
          <IconSvg
            name="arrows/east-mini"
            transitionDuration="fast"
            transitionProperty="transform"
            transitionTimingFunction="ease-in-out"
            transform="rotate(180deg)"
            boxSize={ 5 }
          />
        </LinkInternal>
      </Flex>
      {/*{ statsQueryResult.data?.network_utilization_percentage !== undefined && (*/}
      {/*  <Skeleton isLoaded={ !statsQueryResult.isPlaceholderData } mt={ 1 } display="inline-block">*/}
      {/*    <Text as="span" fontSize="sm">*/}
      {/*      Network utilization:{ nbsp }*/}
      {/*    </Text>*/}
      {/*    <Text as="span" fontSize="sm" color="blue.400" fontWeight={ 700 }>*/}
      {/*      { statsQueryResult.data?.network_utilization_percentage.toFixed(2) }%*/}
      {/*    </Text>*/}
      {/*  </Skeleton>*/}
      {/*) }*/}
      <Box mt={ 3 }>
        { content }
      </Box>
    </Box>
  );
};

export default LatestBlocks;
