import { Box, Flex, Text, VStack } from '@chakra-ui/react';
import React from 'react';

import { route } from 'nextjs-routes';

import useApiQuery from 'lib/api/useApiQuery';
import { AddressHighlightProvider } from 'lib/contexts/addressHighlight';
import useIsMobile from 'lib/hooks/useIsMobile';
import useNewTxsSocket from 'lib/hooks/useNewTxsSocket';
import { TX } from 'stubs/tx';
import LinkInternal from 'ui/shared/LinkInternal';
import SocketNewItemsNotice from 'ui/shared/SocketNewItemsNotice';

import LatestTxsItem from './LatestTxsItem';
import LatestTxsItemMobile from './LatestTxsItemMobile';
import IconSvg from '../shared/IconSvg';

const LatestTransactions = () => {
  const isMobile = useIsMobile();
  const txsCount = isMobile ? 2 : 4;
  const { data, isPlaceholderData, isError } = useApiQuery('homepage_txs', {
    queryOptions: {
      placeholderData: Array(txsCount).fill(TX),
    },
  });

  const { num, socketAlert } = useNewTxsSocket();
  if (isError) {
    return <Text mt={ 4 }>No data. Please reload page.</Text>;
  }

  if (data) {
    const txsUrl = route({ pathname: '/txs' });
    return (
      <VStack p={ 0 } >
        <SocketNewItemsNotice
          borderBottomRadius={ 0 }
          borderRightRadius={ 0 }
          borderTopRightRadius={isMobile ? '12px' : 0 }
          url={ txsUrl }
          num={ num }
          alert={ socketAlert }
          isLoading={ isPlaceholderData }/>
        <Box mb={ 0 } p={ 0 } w={'100%'} display={ { base: 'block', lg: 'none' } }>
          { data.slice(0, txsCount).map(((tx, index) => (
            <LatestTxsItemMobile
              key={ tx.hash + (isPlaceholderData ? index : '') }
              tx={ tx }
              isLoading={ isPlaceholderData }
            />
          ))) }
        </Box>
        <AddressHighlightProvider>
          <Box px={ 4 } mb={ 0 } w="100%" display={ { base: 'none', lg: 'block' } }>
            { data.slice(0, txsCount).map(((tx, index) => (
              <LatestTxsItem
                key={ tx.hash + (isPlaceholderData ? index : '') }
                tx={ tx }
                isLoading={ isPlaceholderData }
              />
            ))) }
          </Box>
        </AddressHighlightProvider>
        <Flex justifyContent="center" alignItems="center" p={ 0 }>
          <LinkInternal
            display="inline-flex"
            alignItems="center"
            justifyContent="flex-end"
            h={'40px'}
            fontSize="sm" href={ txsUrl }>
            View all transactions
            <IconSvg
              name="arrows/east-mini"
              transitionDuration="fast"
              transitionProperty="transform"
              transitionTimingFunction="ease-in-out"
              transform="rotate(180deg)"
              boxSize={ 5 }
            />
          </LinkInternal>
        </Flex>
      </VStack>
    );
  }

  return null;
};

export default LatestTransactions;
